<template>
  <scrollable-dropdown
    align-dropdown-right
    class="preliminary-result-dropdown u-typography-gilroy u-text u-text--s"
  >
    <template slot="button">
      <div class="select-input " data-toggle="dropdown" tabindex="-1">
        <div v-if="isPlaceholderVisible" class="select-placeholder">
          <slot name="placeholder" :placeholder="placeholder">{{ placeholder }}</slot>
        </div>
        <div v-else class="select-value ">
          <slot name="picked-value" v-bind="{ currentValueLabel }">
            {{ currentValueLabel }}
          </slot>
        </div>
        <span class="arrow-down-icon icon-down"></span>
      </div>
    </template>
    <template slot="list-elements">
      <li v-for="selectElement in options" :key="selectElement.label">
        <div
          class="dropdown-option u-typography-gilroy u-text u-text--s"
          @click="select(selectElement)"
        >
          <slot name="list-element" v-bind="{ selectElement }">
            {{ $t(selectElement.label) }}
          </slot>
        </div>
      </li>
    </template>
  </scrollable-dropdown>
</template>

<script>
import ScrollableDropdown from '@/modules/dashboard/components/common/ScrollableDropdown';

export default {
  name: 'DropdownSelect',
  components: { ScrollableDropdown },
  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    currentValueLabel() {
      const { label } = this.options.find(({ value }) => value === this.value);

      return this.$t(label);
    },
    isPlaceholderVisible() {
      return this.placeholder && !this.value;
    }
  },
  methods: {
    select(element) {
      this.$emit('select', element);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .arrow-down-icon {
    width: 20px;
    margin-top: 0;
    margin-left: 10px;
    border: none;
    transition: all 0.1s;
  }

  &:hover {
    cursor: pointer;
  }
}

.open .select-input .arrow-down-icon {
  transform: rotate(180deg);
  transition: all 0.1s;
}

.select-placeholder {
  color: var(--u-color-grey-500);
  font-style: italic;
}

.select-value {
  color: var(--u-color-grey-900);
}

.dropdown-option {
  display: inline-block;
  cursor: pointer;
  padding: 8px 25px;
  color: var(--u-color-grey-900);
}

.open /deep/ .scrollable-dropdown {
  padding: 8px 0 15px;
  margin-top: 0;
  min-width: 0px;
  width: fit-content;

  li {
    padding: 0;
    display: flex;
    break-inside: avoid;
    overflow: hidden;
  }
}
</style>
